import request from '@/js/request'

// 查询活动列表
export function itemList(query) {
    return request({
        url: '/api/website/itemList',
        method: 'get',
        params: query
    })
}
export function itemInfo(query) {
    return request({
        url: '/api/website/itemInfo',
        method: 'get',
        params: query
    })
}

export function dataSave(data) {
    return request({
        url: '/api/website/dataSave',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data: data
    })
}

