<template>
  <div class="home-pane">
    <div class="column-home" v-if="bannerList.length">
      <div class="home-title column-hover" @click="bindJumpLinkChange(bannerList[0].content['field_7'])">
        {{ bannerList[0].content['field_1'] }}
      </div>
      <div class="home-blurb column-hover" @click="bindJumpLinkChange(bannerList[0].content['field_7'])">
        {{ bannerList[0].content['field_2'] }}
      </div>
      <el-row :gutter="32">
        <el-col :span="16" v-if="bannerList[0].fileMap['field_6']">
          <el-carousel height="415px">
            <el-carousel-item v-for="(item,index) in bannerList[0].fileMap['field_6']" :key="index"
                              style="background-color: #999999">
              <img class="banner-img" v-if="item.type==='image/png'" :src="item.path|fullOssUrl" alt="">
              <video class="banner-video" v-if="item.type==='video/mp4'" controls muted autoplay loop>
                <source :src="item.path|fullOssUrl" type="video/webm"/>
              </video>
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <el-col :span="8">
          <div class="home-other">
            <template v-for="(item,index) in firstList">
              <div class="home-other-item" :key="index">
                <span class="serial-num">·</span>
                <span class="column-hover" @click="bindJumpLinkChange(item.content['field_7'])">
                  {{ item.content['field_1'] }}
                </span>
                <div class="column-divider" v-if="index===5"/>
              </div>
            </template>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-row class="column-ad" v-if="adList[0]">
      <el-col :span="24" v-if="adList[0].content&&adList[0].fileMap">
        <img class="ad-img" v-if="adList[0].fileMap['field_3'][0].type==='image/jpeg'"
             :src="adList[0].fileMap['field_3'][0].path|fullOssUrl" alt=""
             @click="bindJumpLinkChange(adList[0].content['field_2'])">
        <video class="ad-video" v-if="adList[0].fileMap['field_3'][0].type==='video/mp4'"
               controls muted autoplay loop>
          <source :src="adList[0].fileMap['field_3'][0].path|fullOssUrl" type="video/webm"/>
        </video>
      </el-col>
    </el-row>
    <el-row :gutter="32">
      <el-col :span="16">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">
              <span class="column-hover">品牌大会</span>
            </div>
          </el-col>
          <el-col :span="24" v-if="conferenceList[0]">
            <el-carousel height="415px">
              <el-carousel-item v-for="(item,index) in conferenceList[0].fileMap['field_6']" :key="index"
                                style="background-color: #999999">
                <img style="width: 100%;height: 100%;display: block;" v-if="item.type==='image/png'"
                     :src="item.path|fullOssUrl" alt="">
                <video style="height: 100%;display: block;" v-if="item.type==='video/mp4'" controls muted autoplay loop>
                  <source :src="item.path|fullOssUrl" type="video/webm"/>
                </video>
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">
              <span class="column-hover" @click="bindJumpListChange(111000,'报业要闻','/list')">报业要闻</span>
            </div>
          </el-col>
          <el-col :span="24" v-for="(item,index) in importantList" :key="index">
            <template v-if="index<1">
              <div class="column-item img-text-in">
                <div class="column-item-img" style="height: 150px">
                  <img v-if="item.fileMap" :src="item.fileMap['field_6'][0].path|fullOssUrl" alt="">
                </div>
                <div class="column-item-text column-hover" @click="bindJumpDetailChange(item.id,'报业要闻')">
                  {{ item.content['field_1'] }}
                </div>
              </div>
            </template>
            <div class="column-item" v-else>
              <div class="column-item-text">
                <span class="serial-num">·</span>
                <span class="column-hover" @click="bindJumpDetailChange(item.id,'报业要闻')">{{
                    item.content['field_1']
                  }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="column-recommend">
      <el-col :span="24" style="margin: 20px 0">
        <div class="column-title">
          <span class="column-hover" @click="bindJumpListChange(131000,'特别推荐','/recommend')">特别推荐</span>
        </div>
      </el-col>
      <el-col :span="24">
        <swiper ref="recommendSwiper"
                class="recommend-swiper"
                :options="recommendSwiperOption">
          <swiper-slide v-for="item in recommendList"
                        :key="item"
                        style="height: 285px;">
            <div class="recommend-item">
              <img :src="item.fileMap['field_6'][0].path|fullOssUrl" alt=""
                   @click="bindJumpLinkChange(item.content['field_7'])">
            </div>
          </swiper-slide>
          <div class="swiper-button-prev recommend-swiper-btn"
               slot="button-prev"></div>
          <div class="swiper-button-next recommend-swiper-btn"
               slot="button-next"></div>
        </swiper>
      </el-col>
    </el-row>
    <el-row :gutter="32">
      <el-col :span="16">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">
              <span class="column-hover" @click="bindJumpListChange(161000,'活动动态','/list')">活动动态</span>
            </div>
          </el-col>
          <el-col :span="12" v-for="(item,index) in actList" :key="index">
            <template v-if="index<1">
              <div class="column-item img-text-in">
                <div class="column-item-img">
                  <img :src="item.fileMap['field_6'][0].path|fullOssUrl" alt="">
                </div>
                <div class="column-item-text column-hover" @click="bindJumpDetailChange(item.id,'活动动态')">
                  {{ item.content['field_1'] }}
                </div>
              </div>
            </template>
            <div class="column-item" v-else>
              <div class="column-item-text">
                <span class="serial-num">·</span>
                <span class="column-hover">{{ item.content['field_1'] }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">
              <span class="column-hover" @click="bindJumpListChange(121000,'品牌传播','/brand')">品牌传播</span>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="column-item img-text-in">
              <div class="column-item-img">
                <el-carousel height="230px" indicator-position="none">
                  <el-carousel-item v-for="(item,index) in brandBannerList" :key="index"
                                    style="background-color: #999999">
                    <img v-if="item.fileMap['field_6']" :src="item.fileMap['field_6'][0].path|fullOssUrl" alt="">
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </el-col>
          <el-col :span="12" v-for="(item,index) in brandList" :key="index">
            <div class="column-item">
              <div class="column-item-text">
                <span class="serial-num">·</span>
                <span class="column-hover" @click="bindJumpLinkChange(item.content['field_7'])">
                  {{ item.content['field_1'] }}
                </span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8">
        <el-row :gutter="32" class="column-matrix">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title column-hover">
              <span class="column-hover">品牌矩阵</span>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="matrix-content" v-if="matrixData[0]&&matrixData[0].fileMap['field_6']">
              <img :src="matrixData[0].fileMap['field_6'][0].path|fullOssUrl" alt="">
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row class="column-ad" v-if="adList[1]">
      <el-col :span="24" v-if="adList[1].content&&adList[1].fileMap">
        <img class="ad-img" v-if="adList[1].fileMap['field_3'][0].type==='image/jpeg'"
             :src="adList[1].fileMap['field_3'][0].path|fullOssUrl" alt=""
             @click="bindJumpLinkChange(adList[1].content['field_2'])">
        <video class="ad-video" v-if="adList[1].fileMap['field_3'][0].type==='video/mp4'"
               controls muted autoplay loop>
          <source :src="adList[1].fileMap['field_3'][0].path|fullOssUrl" type="video/webm"/>
        </video>
      </el-col>
    </el-row>
    <el-row :gutter="32">
      <el-col :span="24" style="margin: 20px 0">
        <div class="column-title">
          <span class="column-hover" @click="bindJumpListChange(151000,'组织体系','/list')">组织体系</span>
        </div>
      </el-col>
      <el-col :span="8" v-for="(item,index) in organizeList" :key="index">
        <div class="column-item img-text-lr" style="align-items: center;">
          <div class="column-item-img" style="height: auto;" v-if="item.fileMap">
            <img :src="item.fileMap['field_6'][0].path|fullOssUrl" alt="">
          </div>
          <div class="column-item-text column-hover"
               style="font-size: 16px;font-weight: bolder;"
               @click="bindJumpDetailChange(item.id,'组织体系',null,item.content['field_7'])">
            {{ item.content['field_1'] }}
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row class="column-ad" v-if="adList[2]">
      <el-col :span="24" v-if="adList[2].content&&adList[2].fileMap">
        <img class="ad-img" v-if="adList[2].fileMap['field_3'][0].type==='image/jpeg'"
             :src="adList[2].fileMap['field_3'][0].path|fullOssUrl" alt=""
             @click="bindJumpLinkChange(adList[2].content['field_2'])">
        <video class="ad-video" v-if="adList[2].fileMap['field_3'][0].type==='video/mp4'"
               controls muted autoplay loop>
          <source :src="adList[2].fileMap['field_3'][0].path|fullOssUrl" type="video/webm"/>
        </video>
      </el-col>
    </el-row>
    <el-row :gutter="32">
      <el-col :span="16" class="column-trends">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">
              <span class="column-hover" @click="bindJumpListChange(201000,'报业动态','/list')">报业动态</span>
            </div>
          </el-col>
          <el-col :span="24" v-for="(item,index) in trendsList" :key="index">
            <div class="trends-title  column-hover"
                 @click="bindJumpDetailChange(item.id,'报业动态',null,item.content['field_7'])">
              {{ item.content['field_1'] }}
            </div>
            <div class="column-item img-text-lr">
              <div class="column-item-img"
                   style="width: 250px;height: 140px;min-width: 250px;"
                   v-if="item.fileMap">
                <img :src="item.fileMap['field_6'][0].path|fullOssUrl" alt="">
              </div>
              <div class="column-item-text column-hover"
                   style="font-size: 15px;line-height: 32px;max-height: 160px;"
                   @click="bindJumpDetailChange(item.id,'报业动态',null,item.content['field_7'])">
                {{ item.content['field_8'] }}
              </div>
            </div>
            <div class="trends-base">
              <div class="trends-base-origin">{{ item.content['field_4'] }}</div>
              <div class="trends-base-time">{{ parseTime(item.content['field_3'], '{y}年{m}月{d}日 {h}:{i}') }}</div>
            </div>
            <div class="column-divider"/>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" class="column-contact">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">联系我们</div>
          </el-col>
          <el-col :span="24" v-for="(item,index) in contactsList" :key="index">
            <div class="contact-item">
              <div class="contact-code">
                <img :src="item.fileMap['field_3'][0].path|fullOssUrl" alt="">
              </div>
              <div class="contact-content">
                <div class="contact-title">{{ item.content['field_1'] }}</div>
                <div class="contact-us" v-html="item.content['field_2']"/>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="column-link">
      <div class="link-title">
        友情连接
      </div>
      <div class="link-list">
        <div class="link-item column-hover"
             v-for="(item,index) in linkList" :key="index" @click="bindJumpLinkChange(item.url)">
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {itemList} from "@/api/api";
import 'swiper/css/swiper.css'
import {parseTime} from "../../js/ruoyi"; // 注意这里的引入

export default {
  name: 'Home',
  data() {
    return {
      //广告
      adList: [],
      //轮播数据
      bannerList: [],
      //首屏数据
      firstList: [],
      //推荐
      recommendList: [],
      //品牌大会
      conferenceList: [],
      //联系我们
      contactsList: [],
      //报业要闻
      importantList: [],
      //报业动态
      trendsList: [],
      //组织结构
      organizeList: [],
      //活动动态
      actList: [],
      //品牌传播
      brandList: [],
      brandBannerList: [],
      //品牌矩阵
      matrixData: [],
      recommendSwiperOption: {
        // 设置垂直轮播vertical,  水平轮播 horizontal
        direction: "horizontal",
        //每组数量
        slidesPerView: 5,
        // 轮播图间距
        spaceBetween: 8,
        // 循环模式选项
        loop: true,
        //  自动滑动
        autoplay: {
          delay: 2500,
          // 如果设置为true，当切换到最后一个slide时停止自动切换。
          stopOnLastSlide: false,
          // 如果设置为false，用户操作swiper之后自动切换不会停止，每次都会重新启动autoplay
          disableOnInteraction: false,
        },
        // 轮播图的切换前进后退按钮，如果想放在轮播图中可以看swiper官方文档，这里写在了轮播外
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      //友情链接
      linkList: [
        {name: '工信部', url: 'http://www.miit.gov.cn/newweb//'},
        {name: '国家广电总局', url: 'http://www.sapprft.gov.cn//'},
        {name: '国家新闻出版署', url: 'http://www.nppa.gov.cn/'},
        {name: 'CNNIC', url: 'http://www.cnnic.net.cn/'},
        {name: '上海报协网', url: 'http://www.shsby.com/'},
        {name: '中国广告网', url: 'http://www.cnad.com/'},
        {name: '北大新媒体', url: 'http://www.looooker.com/'},
        {name: '中国网联网', url: 'http://www.w010w.com.cn/'},
        {name: '今传媒', url: 'http://www.jinchuanmei.com/'},
        {name: '湖北传媒', url: 'http://media.cnhubei.com/'},
        {name: '国务院新闻办', url: 'http://www.scio.gov.cn/index.htm'},
        {name: '新华网', url: 'http://www.xinhuanet.com/'},
        {name: '中国报业', url: 'http://paper.people.com.cn/zgby/'},
        {name: '中国网', url: 'http://www.china.com.cn/'},
        {name: '央视网', url: 'http://www.cctv.com/'},
        {name: '中国日报网', url: 'http://www.chinadaily.com.cn/'},
        {name: '国际在线', url: 'http://www.cri.cn/'},
        {name: '中国青年网', url: 'http://www.youth.cn/'},
        {name: '中国经济网', url: 'http://www.ce.cn/'},
        {name: '中国台湾网', url: 'http://www.taiwan.cn/'},
        {name: '中国西藏网', url: 'http://www.tibet.cn/'},
        {name: '中国文明网', url: 'http://www.wenming.cn/'},
        {name: '央广网', url: 'http://www.cnr.cn/'},
        {name: '中国政府网', url: 'http://www.gov.cn/'},
        {name: '东方网', url: 'http://www.eastday.com//'},
        {name: '新民网', url: 'http://www.xinmin.cn//'},
        {name: '新化月报网', url: 'http://www.xhyb.net.cn/'},
        {name: '艾瑞网', url: 'http://www.iresearch.cn/'},
        {name: '中国人民大学书报资料中心', url: 'http://www.zlzx.org/'},
        {name: '中国产业报协会网', url: 'http://www.acin.org.cn/'},
        {name: '山西出版传媒网', url: 'http://www.sxpmg.com/public1/'},
        {name: '新浪传媒', url: 'http://news.sina.com.cn/media/'},
        {name: '搜狐传媒', url: 'http://media.sohu.com/'},
        {name: '天津在线', url: 'http://www.72177.com/'},
        {name: '网易传媒', url: 'http://dz.cppfoto.com/'},
        {name: '中国摄影报摄影大展专区', url: 'http://dz.cppfoto.com/'},
        {name: '中国新闻网', url: 'http://www.chinanews.com/'},
      ],
    }
  },
  mounted() {
  },
  created() {
    this.getItemList(101000, ['adList'])
    this.getItemList(101400, ['bannerList'])
    this.getItemList(101300, ['firstList'], 13)
    this.getItemList(101100, ['contactsList'])
    this.getItemList(111000, ['importantList'], 9)
    this.getItemList(211000, ['conferenceList'])
    this.getItemList(131000, ['recommendList'], 20)
    this.getItemList(151000, ['organizeList'], 3)
    this.getItemList(161000, ['actList'], 9)
    this.getItemList(171000, ['brandList'], 8)
    this.getItemList(171100, ['brandBannerList'], 20)
    this.getItemList(191000, ['matrixData'])
    this.getItemList(201000, ['trendsList'], 8)
  },
  methods: {
    parseTime,
    getItemList(groupNum, keys, pageCount) {
      let query = {
        queryBOrder: 'desc',
        groupNum: groupNum,
        pageNo: 1,
        pageCount: pageCount ? pageCount : 5
      }
      itemList(query).then(res => {
        let data = res.data;
        if (data && data.resultList) {
          let resultList = data.resultList;
          resultList.forEach(item => {
            if (item.fileList.length) {
              item.fileMap = {}
              for (let i = 0; i < item.fileList.length; i++) {
                const file = item.fileList[i];
                let fieldKey = file.fieldKey;
                // eslint-disable-next-line no-prototype-builtins
                if (item.fileMap.hasOwnProperty(fieldKey)) {
                  item.fileMap[fieldKey].push(file)
                } else {
                  item.fileMap[fieldKey] = [file]
                }
              }
            }
            item.content = JSON.parse(item.content)
          })
          switch (keys.length) {
            case 1:
              this[keys[0]] = resultList
              break;
            case 2:
              this[keys[0]][keys[1]] = resultList
              break;
          }
        }
      });
    },
    hoverEconomyChange(item) {
      this.economyActive = item
    },
    bindJumpListChange(id, name, path) {
      const origin = window.location.origin;
      const url = origin + path + '?name=' + name + '&id=' + id
      window.open(url, '_blank');
    },
    bindJumpDetailChange(id, name, path, link) {
      if (link) {
        window.open(link, '_blank');
      } else {
        const origin = window.location.origin;
        const url = origin + (path ? path : '/detail') + '?name=' + name + '&id=' + id
        window.open(url, '_blank');
      }
    },
    bindJumpLinkChange(url) {
      if (url) {
        window.open(url, '_blank');
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.home-pane {
  .serial-num {
    color: #e00011;
    font-size: 16px;
    font-weight: bold;
    margin-right: 8px;
  }

  .column-ad {
    margin-top: 20px;

    .ad-img {
      width: 100%;
      height: 90px;
      cursor: pointer;
    }

    .ad-video {
      width: 100%;
      height: 90px;
      cursor: pointer;
      object-fit: cover;
    }
  }

  .column-home {
    .home-title {
      font-size: 36px;
      color: #000;
      text-align: center;
      font-weight: bolder;
      margin-top: 20px;
    }

    .home-blurb {
      font-size: 12px;
      color: #000;
      text-align: center;
      line-height: 36px;
      margin: 20px 0;
    }

    .home-other {
      width: 100%;

      .home-other-item {
        font-size: 14px;
        line-height: 30px;
        color: #000;
        overflow: hidden; /* 确保超出容器的文本被隐藏 */
        white-space: nowrap; /* 防止文本换行 */
        text-overflow: ellipsis; /* 超出部分显示省略号 */
      }

      .home-other-item:nth-child(1) {
        font-size: 18px;
        font-weight: bold;

        .serial-num {
          display: none;
        }
      }

      .home-other-item:nth-child(7) {
        font-size: 18px;
        font-weight: bold;

        .serial-num {
          display: none;
        }
      }
    }

    .banner-img {
      width: 100%;
      height: 100%;
      display: block;
    }

    .banner-video {
      height: 100%;
      display: block;
    }
  }

  .column-trends {
    .trends-title {
      font-size: 24px;
      box-sizing: border-box;
      padding-bottom: 24px;
    }

    .trends-base {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0;
    }
  }

  .column-recommend {
    .recommend-swiper {
      .recommend-swiper-btn {
        display: none;
      }

      .recommend-item {
        height: 100%;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          transition: all 0.5s;
        }
      }

      .recommend-item:hover {
        img {
          width: 120%;
          height: 120%;
        }
      }
    }

    .recommend-swiper:hover {
      .recommend-swiper-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: 0;
        height: 36px;
        width: 36px;
        border-radius: 50%;
        background-color: rgba(31, 45, 61, .11);
      }

      .recommend-swiper-btn:hover {
        background-color: rgba(31, 45, 61, .3);
      }

      .recommend-swiper-btn:after {
        color: #FFF;
        font-size: 12px;
      }
    }
  }

  .column-link {
    box-sizing: border-box;
    padding-top: 50px;
    display: flex;
    font-size: 12px;

    .link-title {
      width: 70px;
      min-width: 70px;
    }

    .link-list {
      border-left: 1px solid #d5d5d5;
      display: flex;
      flex-wrap: wrap;

      .link-item {
        padding: 8px 2px 8px 8px;
      }
    }
  }

  .column-matrix {
    .matrix-content {
      width: 100%;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }
}
</style>
