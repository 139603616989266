<template>
  <div class="prove-pane">
    <el-row :gutter="32">
      <el-col :span="24">
        <div class="prove-title">中国好品牌<span>&nbsp;&gt;&nbsp;</span>{{ navName }}</div>
      </el-col>
      <el-col :span="16" class="prove-content">
        <el-row :gutter="32">
          <el-col :span="24">
            <div class="content-header">
              <div class="header-title">证书编号查询</div>
              <div class="header-search">
                <el-input style="width: 230px;margin-right: 20px;" v-model="queryNum"
                          placeholder="请输入证书编号"/>
                <div class="search-btn" @click="bindSearchChange">查询</div>
              </div>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 20px;">
            <div class="content-pane">
              <template v-if="showResult">
                <div v-if="isValid" class="search-result-green">
                  √ 您所查询的证书编号有效
                </div>
                <div v-else class="search-result-red">
                  × 您所输入的证书编号有误或无效
                </div>
              </template>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" class="column-contact">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">联系我们</div>
          </el-col>
          <el-col :span="24" v-for="(item,index) in contactsList" :key="index">
            <div class="contact-item">
              <div class="contact-code">
                <img :src="item.fileMap['field_3'][0].path|fullOssUrl" alt="">
              </div>
              <div class="contact-content">
                <div class="contact-title">{{ item.content['field_1'] }}</div>
                <div class="contact-us" v-html="item.content['field_2']"/>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>

import {itemList} from "@/api/api";

export default {
  name: 'Prove',
  data() {
    return {
      navName: '',
      showResult: false,
      isValid: false,
      queryNum: '',
      //联系我们
      contactsList: [],
    }
  },
  created() {
    let {name, id} = this.$route.query;
    this.navName = name
    this.getItemList(101100, ['contactsList'])
  },
  methods: {
    getItemList(groupNum, keys, pageCount) {
      let query = {
        queryBOrder: 'desc',
        groupNum: groupNum,
        pageNo: 1,
        pageCount: pageCount ? pageCount : 5
      }
      itemList(query).then(res => {
        let data = res.data;
        if (data && data.resultList) {
          let resultList = data.resultList;
          resultList.forEach(item => {
            if (item.fileList.length) {
              item.fileMap = {}
              for (let i = 0; i < item.fileList.length; i++) {
                const file = item.fileList[i];
                let fieldKey = file.fieldKey;
                // eslint-disable-next-line no-prototype-builtins
                if (item.fileMap.hasOwnProperty(fieldKey)) {
                  item.fileMap[fieldKey].push(file)
                } else {
                  item.fileMap[fieldKey] = [file]
                }
              }
            }
            item.content = JSON.parse(item.content)
          })
          switch (keys.length) {
            case 1:
              this[keys[0]] = resultList
              break;
            case 2:
              this[keys[0]][keys[1]] = resultList
              break;
          }
        }
      });
    },
    bindSearchChange() {
      this.getProveList()
    },
    getProveList() {
      let queryNum = this.queryNum;
      if (queryNum) {
        itemList({groupNum: 181000, pageNo: 1, pageCount: 1, queryA: queryNum}).then(res => {
          let data = res.data;
          this.showResult = true
          this.isValid = data && data.resultList && data.resultList.length
        });
      } else {
        this.isValid = false
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.prove-pane {
  .prove-title {
    padding-bottom: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #dddddd;
  }

  .prove-content {
    margin-top: 20px;

    .content-header {
      box-sizing: border-box;
      border-bottom: #ccc 1px dotted;
      padding-bottom: 20px;

      .header-title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
      }

      .header-search {
        margin-top: 20px;
        display: flex;
        justify-content: center;

        .search-btn {
          border: 1px solid #DCDFE6;
          border-radius: 4px;
          box-sizing: border-box;
          line-height: 38px;
          padding: 0 20px;
        }

        .search-btn:hover {
          border: 1px solid #f29095;
          border-radius: 4px;
          box-sizing: border-box;
          line-height: 38px;
          padding: 0 20px;
          cursor: pointer;
          color: #e00011;
        }
      }

      .content-base {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
    }

    .content-pane {
      .search-result-red {
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        font-size: 24px;
        color: #e00011;
      }

      .search-result-green {
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        font-size: 24px;
        color: #0a970a;
      }
    }
  }
}
</style>
