<template>
  <div class="list-pane">
    <el-row :gutter="32">
      <el-col :span="24">
        <div class="list-title">中国好品牌<span>&nbsp;&gt;&nbsp;</span>{{ navName }}</div>
      </el-col>
      <el-col :span="16" class="list-content">
        <el-row :gutter="32">
          <el-col :span="24">
            <el-col :span="24" style="margin: 20px 0">
              <div class="column-title">东部区域</div>
            </el-col>
            <el-col :span="12" v-for="(item,index) in itemData['east']" :key="index">
              <div class="column-item img-text-in" style="margin-bottom: 32px;" @click="bindJumpDetailChange(item.id)">
                <div class="column-item-img">
                  <img :src="item.fileMap['field_6'][0].path|fullOssUrl" alt="">
                </div>
                <div class="column-item-text column-hover">
                  {{ item.content['field_1'] }}
                </div>
              </div>
            </el-col>
          </el-col>
        </el-row>
        <el-row :gutter="32">
          <el-col :span="24">
            <el-col :span="24" style="margin: 20px 0">
              <div class="column-title">南部区域</div>
            </el-col>
            <el-col :span="12" v-for="(item,index) in itemData['south']" :key="index">
              <div class="column-item img-text-in" style="margin-bottom: 32px;" @click="bindJumpDetailChange(item.id)">
                <div class="column-item-img">
                  <img :src="item.fileMap['field_6'][0].path|fullOssUrl" alt="">
                </div>
                <div class="column-item-text column-hover">
                  {{ item.content['field_1'] }}
                </div>
              </div>
            </el-col>
          </el-col>
        </el-row>
        <el-row :gutter="32">
          <el-col :span="24">
            <el-col :span="24" style="margin: 20px 0">
              <div class="column-title">西部区域</div>
            </el-col>
            <el-col :span="12" v-for="(item,index) in itemData['west']" :key="index">
              <div class="column-item img-text-in" style="margin-bottom: 32px;" @click="bindJumpDetailChange(item.id)">
                <div class="column-item-img">
                  <img :src="item.fileMap['field_6'][0].path|fullOssUrl" alt="">
                </div>
                <div class="column-item-text column-hover">
                  {{ item.content['field_1'] }}
                </div>
              </div>
            </el-col>
          </el-col>
        </el-row>
        <el-row :gutter="32">
          <el-col :span="24">
            <el-col :span="24" style="margin: 20px 0">
              <div class="column-title">北部区域</div>
            </el-col>
            <el-col :span="12" v-for="(item,index) in itemData['north']" :key="index">
              <div class="column-item img-text-in" style="margin-bottom: 32px;" @click="bindJumpDetailChange(item.id)">
                <div class="column-item-img">
                  <img :src="item.fileMap['field_6'][0].path|fullOssUrl" alt="">
                </div>
                <div class="column-item-text column-hover">
                  {{ item.content['field_1'] }}
                </div>
              </div>
            </el-col>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" class="column-contact">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">联系我们</div>
          </el-col>
          <el-col :span="24" v-for="(item,index) in contactsList" :key="index">
            <div class="contact-item">
              <div class="contact-code">
                <img :src="item.fileMap['field_3'][0].path|fullOssUrl" alt="">
              </div>
              <div class="contact-content">
                <div class="contact-title">{{ item.content['field_1'] }}</div>
                <div class="contact-us" v-html="item.content['field_2']"/>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {itemList} from "@/api/api";

export default {
  name: 'Economy',
  data() {
    return {
      navName: '',
      itemData: {
        'east': [],
        'south': [],
        'west': [],
        'north': [],
        'centre': [],
      },
      //联系我们
      contactsList: [],
    }
  },
  created() {
    let {name, id} = this.$route.query;
    this.navName = name
    this.getItemList(191000, ['itemData', 'east'])
    this.getItemList(191100, ['itemData', 'south'])
    this.getItemList(191200, ['itemData', 'west'])
    this.getItemList(191300, ['itemData', 'north'])
    this.getItemList(191400, ['itemData', 'centre'])
    this.getItemList(101100, ['contactsList'])
  },
  methods: {
    getItemList(groupNum, keys, pageCount) {
      let query = {
        queryBOrder: 'desc',
        groupNum: groupNum,
        pageNo: 1,
        pageCount: pageCount ? pageCount : 5
      }
      itemList(query).then(res => {
        let data = res.data;
        if (data && data.resultList) {
          let resultList = data.resultList;
          resultList.forEach(item => {
            if (item.fileList.length) {
              item.fileMap = {}
              for (let i = 0; i < item.fileList.length; i++) {
                const file = item.fileList[i];
                let fieldKey = file.fieldKey;
                // eslint-disable-next-line no-prototype-builtins
                if (item.fileMap.hasOwnProperty(fieldKey)) {
                  item.fileMap[fieldKey].push(file)
                } else {
                  item.fileMap[fieldKey] = [file]
                }
              }
            }
            item.content = JSON.parse(item.content)
          })
          switch (keys.length) {
            case 1:
              this[keys[0]] = resultList
              break;
            case 2:
              this[keys[0]][keys[1]] = resultList
              break;
          }
        }
      });
    },
    bindJumpDetailChange(id) {
      const origin = window.location.origin;
      const url = origin + '/detail?name=' + this.navName + '&id=' + id
      window.open(url, '_blank');
    },
  }
}
</script>
<style lang="scss" scoped>
.list-pane {
  .list-title {
    padding-bottom: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #dddddd;
  }

  .list-content {
    margin-top: 20px;

    .serial-num {
      font-size: 16px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
