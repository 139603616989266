import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/index.vue'
import Detail from '@/views/detail/index.vue'
import List from '@/views/list/index.vue'
import Prove from '@/views/prove/index.vue'
import Entry from '@/views/entry/index.vue'
import Frontier from '@/views/frontier/index.vue'
import Economy from '@/views/economy/index.vue'
import Brand from '@/views/brand/index.vue'
import Recommend from '@/views/recommend/index.vue'
import Search from '@/views/search/index.vue'
import Index from '@/views/index.vue'
import error from '@/views/error.vue'


Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '',
        name: 'Index',
        redirect: '/home',
        component: Index,
        children: [
            {
                name: 'Home',
                path: '/home',
                component: Home
            },
            {
                name: 'List',
                path: '/list',
                component: List
            },
            {
                name: 'Detail',
                path: '/detail',
                component: Detail
            },
            {
                name: 'Prove',
                path: '/prove',
                component: Prove
            },
            {
                name: 'Entry',
                path: '/entry',
                component: Entry
            }, {
                name: 'Frontier',
                path: '/frontier',
                component: Frontier
            }, {
                name: 'Search',
                path: '/search',
                component: Search
            },{
                name: 'Economy',
                path: '/economy',
                component: Economy
            },{
                name: 'Brand',
                path: '/brand',
                component: Brand
            },{
                name: 'Recommend',
                path: '/recommend',
                component: Recommend
            },
        ]
    },
    {
        name: 'error',
        path: '/error',
        component: error
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
