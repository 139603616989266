<template>
  <div class="detail-pane">
    <el-row :gutter="32">
      <el-col :span="24">
        <div class="detail-title">中国好品牌<span>&nbsp;&gt;&nbsp;</span>{{ navName }}</div>
      </el-col>
      <el-col :span="16" class="detail-content">
        <el-row :gutter="32">
          <el-col :span="24">
            <div class="content-header" v-if="item.content">
              <div class="title-master">{{ item.content['field_1'] }}</div>
              <div class="title-slave">{{ item.content['field_2'] }}</div>
              <div class="content-base">
                <div class="content-base-time">{{ parseTime(item.content['field_3'], '{y}年{m}月{d}日 {h}:{i}') }}</div>
                <div class="content-base-origin">来源：{{ item.content['field_4'] }}</div>
              </div>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 20px;" v-if="editorMap['field_5']">
            <div class="content-pane" v-html="editorMap['field_5'].text">
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" class="column-contact">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">联系我们</div>
          </el-col>
          <el-col :span="24" v-for="(item,index) in contactsList" :key="index">
            <div class="contact-item">
              <div class="contact-code">
                <img :src="item.fileMap['field_3'][0].path|fullOssUrl" alt="">
              </div>
              <div class="contact-content">
                <div class="contact-title">{{ item.content['field_1'] }}</div>
                <div class="contact-us" v-html="item.content['field_2']"/>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>
<script>

import {itemInfo, itemList} from "@/api/api";
import {parseTime} from "@/js/ruoyi";

export default {
  name: 'Detail',
  data() {
    return {
      navName: '',
      item: {},
      editorMap: {},
      //联系我们
      contactsList: [],
    }
  },
  created() {
    let {name, id} = this.$route.query;
    this.navName = name
    this.getItemInfo(id)
    this.getItemList(101100, ['contactsList'])
  },
  methods: {
    parseTime,
    getItemList(groupNum, keys, pageCount) {
      let query = {
        queryBOrder: 'desc',
        groupNum: groupNum,
        pageNo: 1,
        pageCount: pageCount ? pageCount : 5
      }
      itemList(query).then(res => {
        let data = res.data;
        if (data && data.resultList) {
          let resultList = data.resultList;
          resultList.forEach(item => {
            if (item.fileList.length) {
              item.fileMap = {}
              for (let i = 0; i < item.fileList.length; i++) {
                const file = item.fileList[i];
                let fieldKey = file.fieldKey;
                // eslint-disable-next-line no-prototype-builtins
                if (item.fileMap.hasOwnProperty(fieldKey)) {
                  item.fileMap[fieldKey].push(file)
                } else {
                  item.fileMap[fieldKey] = [file]
                }
              }
            }
            item.content = JSON.parse(item.content)
          })
          switch (keys.length) {
            case 1:
              this[keys[0]] = resultList
              break;
            case 2:
              this[keys[0]][keys[1]] = resultList
              break;
          }
        }
      });
    },
    getItemInfo(id) {
      itemInfo({id: id}).then(res => {
        let item = res.data;
        if (item) {
          let editorMap = {}
          for (let i = 0; i < item.editorList.length; i++) {
            const editor = item.editorList[i];
            let fieldKey = editor.fieldKey;
            editorMap[fieldKey] = editor
          }
          item.content = JSON.parse(item.content)
          this.editorMap = editorMap
          this.item = item
        }
      });
    },
  }
}
</script>
<style lang="scss" scoped>
.detail-pane {
  .detail-title {
    padding-bottom: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #dddddd;
  }

  .detail-content {
    margin-top: 20px;

    .content-header {
      box-sizing: border-box;
      border-bottom: #ccc 1px dotted;
      padding-bottom: 20px;

      .title-master {
        font-size: 24px;
        line-height: 36px;
        font-weight: bold;
        text-align: center;
        letter-spacing: 3px;
      }

      .title-slave {
        margin-top: 20px;
        min-height: 28px;
        line-height: 28px;
        text-align: center;
      }

      .content-base {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
    }

  }
}
</style>
