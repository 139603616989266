<template>
  <div class='official-pane'>
    <div class="official-header">
      <div class="header-top">
        <div class="top-item">
          <img src="../assets/header-logo1.png" alt="">
        </div>
        <div class="top-item">
          <div class="header-search">
            <el-input style="width: 180px;" v-model="search" clearable
                      placeholder="" @keydown.enter.native="bindSearchChange"/>
            <i class="el-icon-search" @click="bindSearchChange"/>
          </div>
        </div>
        <div class="top-item">
          <img src="../assets/header-logo2.png" alt="">
        </div>
      </div>
      <div class="header-nav">
        <div class="nav-item" v-for="(item,index) in navList" :key="index">
          <div @click="bindJumpListChange(item)">{{ item.name }}</div>
        </div>
      </div>
      <div class="header-news">
        <div class="news-tip">
          即时新闻
        </div>
        <div class="news-content">
          <marquee behavior="scroll">
            <span class="content-item column-hover"
                  v-for="(item,index) in newsList" :key="index" @click="bindJumpDetailChange('即时新闻',item.id)">
              <span class="content-symbol">·</span>
              {{ item.content['field_1'] }}
            </span>
          </marquee>
        </div>
      </div>
    </div>
    <div class='official-view'>
      <router-view/>
    </div>
    <div class="official-footer">
      <div class="copyright"><span
          style="font-size: 14px; padding-right: 10px;">Copyright(C)中国好品牌组委会</span><a
          href="https://beian.miit.gov.cn/" target="_blank">
        京ICP备2021022107号-7</a>
      </div>
    </div>
    <div class="official-hover">
      <div class="hover-code" v-if="contactsList[1]">
        <img :src="contactsList[1].fileMap['field_3'][0].path|fullOssUrl" alt="">
      </div>
      <div class="hover-top">
        <img src="@/assets/topping.gif" alt="" @click="bindTopChange">
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store/store'
import {itemList} from "@/api/api";

export default {
  name: 'Index',
  data() {
    return {
      search: '',
      navList: [
        {name: '首页', path: '/home', id: 1},
        {name: '报业要闻', path: '/list', id: 111000},
        {name: '品牌前沿', path: '/list', id: 121000},
        {name: '组织机构', path: '/list', id: 151000},
        {name: '品牌传播', path: '/brand', id: 171000},
        {name: '活动介绍', path: '/blurb', id: 101},
        {name: '活动动态', path: '/list', id: 161000},
        {name: '特别推荐', path: '/recommend', id: 131000},
        {name: '证书查询', path: '/prove', id: 1},
        {name: '报名通道', path: '/entry', id: 1},
      ],
      newsList: [],
      contactsList: [],
      activeId: 1,
      isPhoneNav: false,
      isRouterAlive: true,
      state: store.state,
    }
  },
  created() {
    this.getItemList(101200, 'newsList')
    this.getItemList(101100, 'contactsList')
  },
  methods: {
    getItemList(groupNum, key) {
      itemList({groupNum: groupNum, pageNo: 1, pageCount: 5}).then(res => {
        let data = res.data;
        if (data && data.resultList) {
          let resultList = data.resultList;
          resultList.forEach(item => {
            item.fileMap = {}
            for (let i = 0; i < item.fileList.length; i++) {
              const file = item.fileList[i];
              let fieldKey = file.fieldKey;
              // eslint-disable-next-line no-prototype-builtins
              if (item.fileMap.hasOwnProperty(fieldKey)) {
                item.fileMap[fieldKey].push(file)
              } else {
                item.fileMap[fieldKey] = [file]
              }
            }
            item.content = JSON.parse(item.content)
          })
          this[key] = resultList
        }
      });
    },
    bindTopChange() {
      scrollTo(0, 0);
    },
    bindSearchChange() {
      let search = this.search;
      if (search){
        const origin = window.location.origin;
        const url = origin + '/search?search=' + this.search
        window.open(url, '_blank');
      }
    },
    bindJumpListChange(nav) {
      const {name, id, path} = nav;
      if (path==='/blurb'){
        this.bindJumpDetailChange(name,id)
        return
      }
      const origin = window.location.origin;
      const url = origin + path + '?name=' + name + '&id=' + id
      window.open(url, '_blank');
    },
    bindJumpDetailChange(name, id) {
      const origin = window.location.origin;
      const url = origin + '/detail?name=' + name + '&id=' + id
      window.open(url, '_blank');
    }
  }
}
</script>
<style lang="scss" scoped>
.official-pane {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .official-header {
    width: 1000px;

    .header-top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 20px 0 8px 0;

      .top-item {
        position: relative;
        display: flex;
        align-items: end;
        img{
          width: auto;
          height: 160px;
        }
      }

      .header-search {
        display: flex;
        justify-content: center;
        align-items: center;

        ::v-deep .el-input__inner {
          border: 1px solid #DCDFE6;
        }

        .el-icon-search {
          cursor: pointer;
          font-size: 24px;
          color: #bcbcbc;
          font-weight: bolder;
          margin-left: 8px;
        }
      }
    }

    .header-nav {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 14px 80px;
      box-sizing: border-box;
      background-color: #e00011;
      font-size: 16px;
      color: #fff;

      .nav-item {
        border: 1px solid transparent;
      }

      .nav-item:hover {
        cursor: pointer;
        box-sizing: border-box;
        border-bottom: 1px solid #fff;
      }
    }

    .header-news {
      width: 100%;
      padding: 20px 0;
      display: flex;
      align-items: center;

      .news-tip {
        width: 78px;
        line-height: 35px;
        text-align: center;
        background-color: #e8e8e8;
        color: #000;
      }

      .news-content {
        flex: 1;
        box-sizing: border-box;
        padding: 0 10px;
        color: #000;

        .content-item {
          margin-right: 20px;

          .content-symbol {
            color: #e00011;
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
    }
  }

  .official-view {
    position: relative;
    width: 1000px;
    box-sizing: border-box;
    padding-bottom: 50px;
  }

  .official-footer {
    width: 100%;
    height: auto;
    background-color: #f8f8f8;
    padding: 0;


    .copyright {
      padding: 40px;
      text-align: center;
    }

    .copyright a {
      font-size: 12px;
      color: #000
    }
  }

  .official-hover {
    position: fixed;
    right: 15px;
    bottom: 100px;

    .hover-code {
      box-sizing: border-box;
      width: 150px;
      height: 150px;
      padding: 15px;
      background-color: #eeeeee;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .hover-top {
      cursor: pointer;
      margin-top: 30px;
      width: 150px;
      height: 98px;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  @media screen and (max-width: 996px) {

    ::v-deep.el-drawer {
      width: auto !important;
    }
  }
}

</style>
