<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>

export default {
  data() {
    return {}
  },
  created() {
  },
  methods: {
  }
}
</script>
<style lang="scss">
#app {
  height: 100%;
  width: 100%;
}

.tox-tinymce-aux {
  z-index: 2024 !important;
}
</style>
