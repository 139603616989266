<template>
  <div class="entry-pane">
    <el-row :gutter="32">
      <el-col :span="24">
        <div class="entry-title">中国好品牌<span>&nbsp;&gt;&nbsp;</span>{{ navName }}</div>
      </el-col>
      <el-col :span="16" class="entry-content">
        <el-row :gutter="32">
          <el-col :span="24">
            <div class="content-header">
              <div class="header-title">全国优秀企业品牌推选活动报名</div>
            </div>
          </el-col>
          <el-col :span="24" style="margin-top: 20px;">
            <div class="content-pane">
              <el-form ref="entryForm" :model="entry" :rules="entryRules">
                <el-col>
                  <el-form-item label="公司名称" prop="company">
                    <el-input v-model="entry.company" placeholder="请输入贵公司的名称" clearable/>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="联系人" prop="contacts">
                    <el-input v-model="entry.contacts" placeholder="请输入您的姓名" clearable/>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="entry.phone" placeholder="请输入您的电话" clearable/>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="表单下载">
                    <div class="entry-material">
                      <el-upload
                          action
                          :http-request="httpRequest"
                          :before-remove="beforeRemove"
                          :before-upload="beforeUpload"
                          :multiple="false"
                          :limit="1"
                          :file-list="fileList">
                        <el-button type="danger">点击上传</el-button>
                      </el-upload>
                      <div>
                        <el-button type="danger" plain icon="el-icon-download" @click="downloadMaterial">
                          报名表单下载.docx
                        </el-button>
                      </div>

                    </div>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-form-item label="公司介绍" prop="blurb">
                    <el-input class="textarea" type="textarea" :rows="5" v-model="entry.blurb"
                              placeholder="请输入贵公司的介绍" clearable/>
                  </el-form-item>
                </el-col>
                <el-col>
                  <el-button type="danger" @click="bindEntryChange">提交资料</el-button>
                </el-col>
              </el-form>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" class="column-contact">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">联系我们</div>
          </el-col>
          <el-col :span="24" v-for="(item,index) in contactsList" :key="index">
            <div class="contact-item">
              <div class="contact-code">
                <img :src="item.fileMap['field_3'][0].path|fullOssUrl" alt="">
              </div>
              <div class="contact-content">
                <div class="contact-title">{{ item.content['field_1'] }}</div>
                <div class="contact-us" v-html="item.content['field_2']"/>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-dialog
        :show-close="false"
        :center="true"
        :visible.sync="isSubmit"
        width="30%">
      <div class="entry-success">
        <img class="img-success" src="@/assets/success.png" alt=""/>
        <p class="tip-success">提交成功</p>
        <p class="tip-info">报名表提交后，我们的工作人员将与您<br>电话联系，请保持电话畅通。</p>
      </div>
      <span slot="footer" class="dialog-footer">
          <el-button type="danger" @click="isSubmit = false">我知道了</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>

import {dataSave, itemList} from "@/api/api";

export default {
  name: 'Entry',
  data() {
    const validateMobile = (rule, value, callback) => {
      const re = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
      if (!re.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    const validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error('必填'))
      }
      const re = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/
      if (!re.test(value)) {
        callback(new Error('不能包含特殊符号'))
      } else {
        callback()
      }
    }
    return {
      navName: '',
      entry: {
        company: "",
        blurb: "",
        contacts: "",
        phone: "",
      },
      entryRules: {
        company: [{required: true, validator: validateName, trigger: 'blur'}],
        contacts: [{required: true, validator: validateName, trigger: 'blur'}],
        phone: [{required: true, validator: validateMobile, trigger: 'blur'}],
        blurb: [{required: true, validator: validateName, trigger: 'blur'}],
      },
      isSubmit: false,
      fileList: [],
      file: null,
      //联系我们
      contactsList: [],
    }
  },
  created() {
    let {name, id} = this.$route.query;
    this.navName = name
    this.getItemList(101100, ['contactsList'])
  },
  methods: {
    getItemList(groupNum, keys, pageCount) {
      let query = {
        queryBOrder: 'desc',
        groupNum: groupNum,
        pageNo: 1,
        pageCount: pageCount ? pageCount : 5
      }
      itemList(query).then(res => {
        let data = res.data;
        if (data && data.resultList) {
          let resultList = data.resultList;
          resultList.forEach(item => {
            if (item.fileList.length) {
              item.fileMap = {}
              for (let i = 0; i < item.fileList.length; i++) {
                const file = item.fileList[i];
                let fieldKey = file.fieldKey;
                // eslint-disable-next-line no-prototype-builtins
                if (item.fileMap.hasOwnProperty(fieldKey)) {
                  item.fileMap[fieldKey].push(file)
                } else {
                  item.fileMap[fieldKey] = [file]
                }
              }
            }
            item.content = JSON.parse(item.content)
          })
          switch (keys.length) {
            case 1:
              this[keys[0]] = resultList
              break;
            case 2:
              this[keys[0]][keys[1]] = resultList
              break;
          }
        }
      });
    },
    bindEntryChange() {
      this.$refs.entryForm.validate(valid => {
        if (valid) {
          let {company, contacts, phone, blurb} = this.entry;
          const form = new FormData()
          form.append('file', this.file)
          form.append('company', company)
          form.append('contacts', contacts)
          form.append('phone', phone)
          form.append('blurb', blurb)
          dataSave(form).then(() => {
            this.fileList = [];
            this.file = null;
            this.isSubmit = true;
            this.$refs.entryForm.resetFields();
          });
        } else {
          return false
        }
      })
    },
    downloadMaterial() {
      const a = document.createElement('a');
      a.href = './企业信息登记表.docx';
      a.download = '企业信息登记表.docx';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    beforeRemove(file) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 上传前
    beforeUpload(file) {
      console.log(file);
      // return new Promise((resolve, reject) => {
      //   imageCompress(file).then((res) => resolve(res)).catch(() => reject())
      // })
    },
    httpRequest(files) {
      console.log(files)
      this.file = files.file;
    }
  }
}
</script>
<style lang="scss" scoped>
.entry-pane {
  .entry-title {
    padding-bottom: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #dddddd;
  }

  .entry-content {
    margin-top: 20px;

    .content-header {
      box-sizing: border-box;
      border-bottom: #ccc 1px dotted;
      padding-bottom: 20px;

      .header-title {
        font-size: 24px;
        font-weight: bold;
        text-align: center;
      }

      .title-slave {
        margin-top: 20px;
        text-align: center;
      }

      .content-base {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }
    }

    .content-pane {

      .entry-material {
        overflow: auto;
        position: relative;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;

      }
    }
  }

  .entry-success {
    width: 100%;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img-success{
      width: 60px;
      height: 60px;
      display: block;
    }
    .tip-success{
      font-size: 24px;
      line-height: 48px;
    }
    .tip-info{
      text-align: center;
    }
  }
}
</style>
