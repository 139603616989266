<template>
  <div class="list-pane">
    <el-row :gutter="32">
      <el-col :span="24">
        <div class="list-title">中国好品牌<span>&nbsp;&gt;&nbsp;</span>{{ navName }}</div>
      </el-col>
      <el-col :span="16" class="list-content">
        <el-row :gutter="32">
          <el-col :span="24" v-for="(item,index) in itemList" :key="index">
            <div class="column-item">
              <div class="column-item-text">
                <span class="serial-num">·</span>
                <span class="column-hover" @click="bindJumpDetailChange(item)">
                  {{ item.content['field_1'] }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="8" class="column-contact">
        <el-row :gutter="32">
          <el-col :span="24" style="margin: 20px 0">
            <div class="column-title">联系我们</div>
          </el-col>
          <el-col :span="24" v-for="(item,index) in contactsList" :key="index">
            <div class="contact-item">
              <div class="contact-code">
                <img :src="item.fileMap['field_3'][0].path|fullOssUrl" alt="">
              </div>
              <div class="contact-content">
                <div class="contact-title">{{ item.content['field_1'] }}</div>
                <div class="contact-us" v-html="item.content['field_2']"/>
              </div>
            </div>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {itemList} from "@/api/api";

export default {
  name: 'List',
  data() {
    return {
      navName: '',
      itemList: [],
      //联系我们
      contactsList: [],
    }
  },
  created() {
    let {name, id} = this.$route.query;
    this.navName = name
    this.getItemList(id, ['itemList'], 20)
    this.getItemList(101100, ['contactsList'])
  },
  methods: {
    getItemList(groupNum, keys, pageCount) {
      let query = {
        queryBOrder: 'desc',
        groupNum: groupNum,
        pageNo: 1,
        pageCount: pageCount ? pageCount : 5
      }
      itemList(query).then(res => {
        let data = res.data;
        if (data && data.resultList) {
          let resultList = data.resultList;
          resultList.forEach(item => {
            if (item.fileList.length) {
              item.fileMap = {}
              for (let i = 0; i < item.fileList.length; i++) {
                const file = item.fileList[i];
                let fieldKey = file.fieldKey;
                // eslint-disable-next-line no-prototype-builtins
                if (item.fileMap.hasOwnProperty(fieldKey)) {
                  item.fileMap[fieldKey].push(file)
                } else {
                  item.fileMap[fieldKey] = [file]
                }
              }
            }
            item.content = JSON.parse(item.content)
          })
          switch (keys.length) {
            case 1:
              this[keys[0]] = resultList
              break;
            case 2:
              this[keys[0]][keys[1]] = resultList
              break;
          }
        }
      });
    },
    bindJumpDetailChange(item) {
      const {id, content} = item
      if (content && content['field_7']) {
        window.open(content['field_7'], '_blank');
      } else {
        const origin = window.location.origin;
        const url = origin + '/detail?name=' + this.navName + '&id=' + id
        window.open(url, '_blank');
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.list-pane {
  .list-title {
    padding-bottom: 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #dddddd;
  }

  .list-content {
    margin-top: 20px;

    .serial-num {
      font-size: 16px;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
