import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store/store'
import ElementUI from 'element-ui'
import VueClipboard from 'vue-clipboard2'
import './js/vueFilter'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/element-ui.scss' // element-ui修改样式
import "@/styles/font.css"
import SwiperCss from 'swiper/css/swiper.min.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'

Vue.config.productionTip = false
Vue.use(ElementUI).use(VueClipboard).use(VueAwesomeSwiper, {SwiperCss})

function onscrollTop() {
    window.scrollTo(0, 0);
    // chrome
    document.body.scrollTop = 0
    // firefox
    document.documentElement.scrollTop = 0
    // safari
    window.pageYOffset = 0
}

router.beforeEach((to, from, next) => {
    store.setState();
    store.setCurrentRoute(null, to.name);
    onscrollTop();
    const isPhone = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //判断该页面有channel
    if(isPhone){
        //路由切换时，如果没有就添加，有就跳过执行，添加固定参数
        if (!to.query.t) {
            //准备一个跳转的query对象
            let query = to.query
            query.t = new Date().getTime()
            next({
                path: to.path,
                query: query
            })
        } else {
            next()
        }
    }else{
        next()
    }
})
new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
// store,
