/**
 * Created by Administrator on 2017/9/15.
 */
import Vue from 'vue';
import moment from 'moment'
import {config} from '../js/config'

Vue.filter('upperCase', function (val) {
    if (val == null || val == '') {
        return val;
    } else {
        return val.toUpperCase();
    }
});


Vue.filter('convertDate', function (val) {
    if (val == null || val == '') {
        return ''
    } else {
        let date = new Date(val);
        return moment(date).format('YYYY-MM-DD');
    }
});

Vue.filter('convertTime', function (time, format) {
    if (time) {
        let date = new Date(time);
        return moment(date).format(format ? format : 'YYYY-MM-DD');
    }
    return ''
});

Vue.filter('convertActStatus', function (val, startTime, endTime) {
    if (val == null || val == '') {
        return ''
    } else {
        if (val.index != 1) {
            return val.desc;
        }

        if (startTime == null || endTime == null) {
            return '未知'
        }

        let now = moment().valueOf();
        if (startTime > now) {
            return '未开始';
        } else if (startTime < now && endTime > now) {
            return '进行中';
        } else if (endTime < now) {
            return '已结束'
        }
    }
});

Vue.filter('convertActStatusColorClass', function (val, startTime, endTime) {
    if (val == null || val == '') {
        return ''
    } else {
        if (val.index != 1) {
            return 'actStatus-color-red';
        }

        if (startTime == null || endTime == null) {
            return 'actStatus-color-red';
        }

        let now = moment().valueOf();
        if (startTime > now) {
            return 'actStatus-color-yellow';
        } else if (startTime < now && endTime > now) {
            return 'actStatus-color-green';
        } else if (endTime < now) {
            return 'actStatus-color-gray'
        }
    }
});

Vue.filter('fullOssUrl', function (val) {
    if (val == null || val == '') {
        return ''
    } else {
        let lowerVal = val.toLowerCase();
        if (lowerVal.startsWith('http://') || lowerVal.startsWith('https://')) {
            return val;
        }
        return config.ossHost + val;
    }
});

